/*---------------------------------------------------------

  COMPONENT: OUR GLOBAL NAV

  // DO NOT COPY `components/_nav-global` into curriculum projects.
  // This CSS is for demonstration or active development purposes, only.
  // Sync with canonical Gateway App CSS before proceeding.

  // The canonical CSS for this component is contained within
  // gateway-app/app/assets/stylesheets/global_nav.css
  
  As a “single-source” HTML/CSS/JS component housed in the gateway app and
  inserted dynamically into our curricula websites, this component class 
  includes the `g-` prefix for “gateway” or “global”. This namespace enables 
  the CSS for this component to function in parallel with the legacy 
  `c-nav-global` component until such time as that local-to-the-curricula 
  CSS is removed from those builds.

---------------------------------------------------------*/

/*---------------------------------------------------------

  COMPONENT: OUR GLOBAL NAV -- ROBOTO FONTS

  • Roboto 500 is universally necessary.
  • Roboto 700 is only necessary in the Curriculum Gateway,
    for the “Create New Account” link.

---------------------------------------------------------*/

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;1,700&display=swap");

/*---------------------------------------------------------

  COMPONENT: OUR GLOBAL NAV -- VARIABLES

---------------------------------------------------------*/

/* Color palette */

:root {

/* Colors with "-our-" in their name are sourced from the OUR brand guidelines:
   https://open-up-cms.herokuapp.com/styleguides/branding/colors.html */

--g-nav-global-orange:                   #f1592a; /* Modified OUR primary orange to WCAG AA+ standards */
--g-nav-global-our-primary-yellow:       #feb300;
--g-nav-global-our-primary-dark-gray:    #3e2e2f;
--g-nav-global-white:                    #fff;

/*
  Typographic sizes
  Font sizes based on minor third (1.2) http://www.modularscale.com/?1&em&1.2
*/

--g-nav-global-font-base-min: 16px;
--g-nav-global-font-base-max: 20px;
--g-nav-global-fluid-em:      calc(16px + (20 - 16) * ((100vw - 320px)/(1320 - 320)));
--g-nav-global-delta:         1.2em;
--g-nav-global-epsilon:       0.833em;

}

/* Custom Media Declarations for Breakpoints */

@custom-media --g-nav-global-bp-min-minus (max-width: 536px);
@custom-media --g-nav-global-bp-min (min-width: 537px);
@custom-media --g-nav-global-bp-mid-minus (max-width: 1023px);
@custom-media --g-nav-global-bp-mid (min-width: 1024px);
@custom-media --g-nav-global-bp-max-minus (max-width: 1319px);
@custom-media --g-nav-global-bp-max (min-width: 1320px);

/*---------------------------------------------------------

  COMPONENT: OUR GLOBAL NAV -- BASE

---------------------------------------------------------*/

.g-nav-global {
   font-size: var(--g-nav-global-font-base-min);
   font-family: "Roboto", "Trebuchet MS", Trebuchet, Tahoma, sans-serif;
   font-weight: 500;
   text-rendering: optimizelegibility; /* Can cause problems in some versions of Android */
   text-size-adjust: none;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   line-height: 1.45;

   /* Fluid from 16px to 20px between breakpoints */

   @media (--g-nav-global-bp-min) {
     & {
      font-size: clamp(16px, calc(16px + (20 - 16) * ((100vw - 320px)/(1320 - 320))), 20px);
     }
   }
}

.g-nav-global * {
  box-sizing: border-box;
  &::before, &::after {
    box-sizing: border-box;
  }
}

/*---------------------------------------------------------

  COMPONENT: OUR GLOBAL NAV -- STYLES

---------------------------------------------------------*/

.g-nav-global {
  @media (--g-nav-global-bp-mid) {
    & {
      z-index: 8675309;
      width: 100%;
    }
  }
}

.g-nav-global__wrapper {
  padding: 0.75em 1em;
  background: var(--g-nav-global-orange);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position:relative;
  @media (--g-nav-global-bp-mid) {
    & {
      padding: 0 0.25em 0 1em;
    }
  }
}

.g-nav-global__logolink {
  display: block;
  @media (--g-nav-global-bp-mid) {
    & {
      padding: 0.83125em 0;
    }
  }
}

.g-nav-global__logoimg {
  display: block;
  height: 2em;
  width: auto;
}

button.g-nav-global__toggle {
  -webkit-appearance: none;
  appearance: none;
  color: var(--g-nav-global-white);
  font: inherit;
  font-size: var(--g-nav-global-epsilon);
  line-height: calc(var(--g-nav-global-fluid-em));
  text-transform: uppercase;
  cursor: pointer;
  background-color: var(--g-nav-global-orange);
  border: 3px var(--g-nav-global-our-primary-yellow) solid; /* Normally, I prefer rems/ems for all dimensions, but browser rounding on an element this small results in different pixel widths across adjacent instances of this same element. The difference is noticeable and annoying.  */
  border-radius: calc(1.25 * var(--g-nav-global-fluid-em));
  padding: calc(0.5 * var(--g-nav-global-fluid-em)) calc(var(--g-nav-global-fluid-em));
  width: calc(5 * var(--g-nav-global-fluid-em));
  margin: calc(0.25rem * var(--g-nav-global-fluid-em)) 0;
  @media (--g-nav-global-bp-mid) {
    & {
      display: none;
    }
  }
  &:link,
  &:visited,
  &:hover,
  &:active {
    color: var(--g-nav-global-white);
  }
}

.g-nav-global__dialog {
  padding: 0.75em 1em;
  background: var(--g-nav-global-orange);
  border: unset;
  text-align: right;
  z-index: 8675309;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  @media (--g-nav-global-bp-mid) {
    & {
      display: none;
    }
  }
}

/* Prevent double-scroll bar (dialog and body) in
shorter viewports that cause overflow for both */
body:has(.g-nav-global__dialog[open]) {
  overflow-y: hidden;
}

.g-nav-global__nav {
  display: none;
  @media (--g-nav-global-bp-mid) {
    & {
      display: unset;
    }
  }
}

.g-nav-global__list {
  list-style: none;
  margin: 1em auto 0;
  padding: 2em 1em 2em;
  text-align: center;
  position: static;
  @media (--g-nav-global-bp-mid) {
    & {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
    }
  }
}

.g-nav-global__list {
  /* Annoying necessity - override of OUR wordpress theme defaults for ul */
  position: static;
}

.g-nav-global__listitem {
  list-style: none;
  hyphens: none;
  margin: 0;
  padding: 0.65625em 0 0;
  line-height: 1.25;
  @media (--g-nav-global-bp-mid) {
    & {
      padding: 1.15625em 0;
    }
  }
}

/* Annoying necessity - override of OUR wordpress theme defaults for ul li */
.g-nav-global__listitem::before {
  content: normal;
}

.g-nav-global__label {
  color: var(--g-nav-global-white);
  font-size: var(--g-nav-global-delta);
  display: inline-block;
  @media (--g-nav-global-bp-mid) {
    & {
      font-size: var(--g-nav-global-epsilon);
    }
  }
}

.g-nav-global__link,
.g-nav-global__link:link,
.g-nav-global__link:visited,
.g-nav-global__link:active {
  font-size: var(--g-nav-global-delta);
  font-weight: 500;
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: var(--g-nav-global-white);
  padding: calc(0.5 * var(--g-nav-global-fluid-em));
  transition: none;
  @media (--g-nav-global-bp-mid) {
    & {
      font-size: var(--g-nav-global-epsilon);
      padding: 0 var(--g-nav-global-font-base-max);
    }
  }
  @media (--g-nav-global-bp-mid) {
    &::before,
    &::after {
      display: block;
      height: 3px; /* Normally, I prefer rems/ems for all dimensions, but browser rounding on an element this small results in different pixel widths across adjacent instances of this same element. The difference is noticeable and annoying.  */
      width: 100%;
      content: "";
      position: relative;
      top: 2px;
    }
  }
  @media (--g-nav-global-bp-mid-minus) {
    &::before,
    &::after {
      position: absolute;
      bottom: calc(0.25 * var(--g-nav-global-fluid-em));
      left: 0;
      right: 0;
      width: auto;
    }
  }
  &:hover {
    color: var(--g-nav-global-white);
    text-decoration: none;
  }
  @media (--g-nav-global-bp-mid) {
    &:hover::after,
    &:focus::after {
      background: var(--g-nav-global-our-primary-yellow);
    }
  }
}

/* For "Create New Account" link in Curriculum Gateway app */
.g-nav-global__label + .g-nav-global__link,
.g-nav-global__label + .g-nav-global__link:link,
.g-nav-global__label + .g-nav-global__link:visited,
.g-nav-global__label + .g-nav-global__link:active {
  padding-inline-start: 0;
  font-weight: 700;
  &::before,
  &::after {
    display: block;
    height: 3px; /* Normally, I prefer rems/ems for all dimensions, but browser rounding on an element this small results in different pixel widths across adjacent instances of this same element. The difference is noticeable and annoying. */
    width: 100%;
    content: "";
    position: relative;
    top: 2px;
  }
  &::after {
    background: var(--g-nav-global-our-primary-yellow);
    opacity: 0.75;
  }
  &:hover::after {
    opacity: 1;
  }
  @media (--g-nav-global-bp-mid) {
    &::before,
    &::after {
      top: 0;
    }
  }
}

/* Inserts a separator before the last link,
either “Log Out” or “Create New Account”. */
.g-nav-global__listitem:last-child:not(:only-child) {
  & .g-nav-global__link,
  & .g-nav-global__link:link,
  & .g-nav-global__link:visited,
  & .g-nav-global__link:active {
    @media (--g-nav-global-bp-mid) {
      & {
        margin-inline-start: var(--g-nav-global-font-base-max);
        padding-inline-start: calc(2 * var(--g-nav-global-font-base-max));
        border-inline-start: 1px solid var(--g-nav-global-white);
      }
    }
  }
}

.g-nav-global__notification {
  font-size: 0.75em;
  padding: 1em 3em;
  background-color: orange;
  text-align: center;
}

.g-nav-global__notification p {
  font-size: 1em;
  font-weight: 500;
  line-height: 1.25;
  margin: 0;
}

.g-nav-global__notification a {
  color: var(--g-nav-global-our-primary-dark-gray);
  border: none;
  text-decoration: underline;
  white-space: nowrap;
}

/* Sometimes necessary to keep short link text
in the notification from wrapping. */
.g-nav-global__helper-nowrap {
  white-space: nowrap;
}
